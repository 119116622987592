import { Tab, Row, Nav } from 'react-bootstrap'
function VisionMission() {
  return (
    <Tab.Container defaultActiveKey="nav-who">
      <Row>
        <Nav variant="pills" as='nav' bsPrefix=''>
          <Nav.Link eventKey="nav-who" as='button'>Who We Are</Nav.Link>
          <Nav.Link eventKey="nav-vision" as='button'>Our Vision</Nav.Link>
          <Nav.Link eventKey="nav-history" as='button'>Our Mission</Nav.Link>
        </Nav>
      </Row>
      <Row>
        <Tab.Content>
          <Tab.Pane eventKey="nav-who">
            <p>We are a team dedicated to sharing hardware resources amongst various institutions to eliminate unnecessary high costs 
              of setting up physical laboratories. We handle the lab equipment for you!
            </p>
          </Tab.Pane>
          <Tab.Pane eventKey="nav-vision">
            <p>To be a global leader in providing online experiments experience for all institutions</p>
          </Tab.Pane>
          <Tab.Pane eventKey="nav-history">
            <p>Use technology to improve online learning through remote laboratory experiments</p>
          </Tab.Pane>
        </Tab.Content>
      </Row>
    </Tab.Container>
  )
}

export default VisionMission