import { useMatch } from 'react-router-dom'

function OperationStatus() {
    const match = useMatch('/operation/:status')
    const status = match ? match.params.status || 'fail' : 'fail';
    const hasSucceeded = status === 'success';
    console.log('status:', status)
    return (
        <section className='services-area services-eight'>
            <div className='container text-center mt-4'>
                <h1>Hello there</h1>
                <h2> Your Operation has {hasSucceeded ? "Succeeded." : "failed"}</h2>
                {hasSucceeded && <p>We shall reach out to you soon</p>}
                {!hasSucceeded && <p className='text-danger'>We were not able to receive your message. Please try contacting us directly </p>}


            </div>
        </section>
    )
}

export default OperationStatus