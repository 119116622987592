import { Route, Routes } from 'react-router-dom'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import Services from './components/Services'
import Contact from './components/Contact'
import Home from './components/Home'
import Products from './components/Products'
import OperationStatus from './components/OperationStatus'

function App() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/products' element={<Products />} />
        <Route path='/operation/:status' element={<OperationStatus />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
