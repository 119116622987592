import ServicesArticles from "./ServicesArticles"

function Services() {
  return (
    <>
      <section className="services-area services-eight">
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h2 className="fw-bold">Remote Laboratories</h2>
                  <p>
                    At Smarter Labs, we offer remote access to physical experiment with real-time data
                    streaming. No simulations are involved.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <i className="lni lni-cloud-network"></i>
                </div>
                <div className="service-content">
                  <h4>Connect Systems</h4>
                  <p>
                    We connect physical experiment circuits to students and allow both control and monitoring of the
                    hardware circuits under study. The data is streamed in realtime.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <i className="lni lni-code-alt"></i>
                </div>
                <div className="service-content">
                  <h4>Develop Custom Experiments</h4>
                  <p>
                    We develop custom experiments and allow targeted users to access these
                    built Systems through our portal. New installations not required in most cases.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services">
                <div className="service-icon">
                  <i className="lni lni-reload"></i>
                </div>
                <div className="service-content">
                  <h4>Regular Updates</h4>
                  <p>
                    We deliver updates to existing Systems and rollout bug fixes and updated configuration Regularly.
                    Our sofware is usally up to date to avoid vulnerabilities
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     <ServicesArticles />
    </>
  )
}

export default Services