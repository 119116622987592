import axios from 'axios'


const send = async data => {
    const contactUrl = 'https://dadseb56dh.execute-api.us-east-1.amazonaws.com/dev/api/v1/contact'
    const response = await axios.post(contactUrl, data)
    return response.data
}

const emailService = { send }
export default emailService