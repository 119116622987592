import {Link} from 'react-router-dom'

function Footer() {
  return (
    <>
      <footer className="footer-area footer-eleven">
        {/* <!-- Start Footer Top --> */}
        <div className="footer-top">
          <div className="container">
            <div className="inner-content">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                  {/* <!-- Single Widget --> */}
                  <div className="footer-widget f-about">
                    <div className="logo">
                      <Link to="/">
                        <img src="assets/images/logo-no-background.png" alt="#" className="img-fluid" />
                      </Link>
                    </div>
                    <p>
                     Improving education and lives through providing online labs.
                    </p>
                    <p className="copyright-text">
                      <span>© 2022 smarter labs</span>Designed and Developed by  
                      <Link to="/">  Smarter Laboratories Ltd</Link>
                    </p>
                  </div>
                  {/* <!-- End Single Widget --> */}
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  {/* <!-- Single Widget --> */}
                  <div className="footer-widget f-link">
                    <h5>Solutions</h5>
                    <ul>
                      <li><Link to='/'>Electronic Labs</Link></li>
                      <li><Link to='/'>Power Labs</Link></li>
                      <li><Link to='/'>Physics Labs</Link></li>
                    </ul>
                  </div>
                  {/* <!-- End Single Widget --> */}
                </div>
	  {/*      <div className="col-lg-2 col-md-6 col-12">
                  {/* <!-- Single Widget 
                  <div className="footer-widget f-link">
                    <h5>Support</h5>
                    <ul>
                      <li><Link to='/'>Pricing</Link></li>
                      <li><Link to='/'>Installation</Link></li>
                      <li><Link to='/'>Survey</Link></li>
                      <li><Link to='/'>Designing</Link></li>
                    </ul>
                  </div>
                  End Single Widget 
                </div>-->*/}
                <div className="col-lg-5 col-md-6 col-12">
                  {/* <!-- Single Widget --> */}
                  <div className="footer-widget newsletter">
                    <h5>Subscribe</h5>
                    <p>Subscribe to our newsletter for the latest updates</p>
                    <form action="#" method="get" target="_blank" className="newsletter-form">
                      <input name="EMAIL" placeholder="Email address" required="required" type="email" />
                      <div className="button">
                        <button className="sub-btn">
                          <i className="lni lni-envelope"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <!-- End Single Widget --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--/ End Footer Top --> */}
      </footer>
    </>
  )
}

export default Footer
