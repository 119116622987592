function ServicesArticles() {
  const styles = {
    padding: "0px"
  }
  return (
    <>
      <div class="latest-news-area section" style={styles}>
        {/* <!--======  Start Section Title Five ======--> */}
        <div class="section-title-five">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="content">
                  <h6>Products</h6>
                  <h2 class="fw-bold">Our Software & Hardware Products</h2>
                  <p>
                    We offer a set of Products to allow our clients to connect to cloud hosted experiment hardware.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!--======  End Section Title Five ======--> */}
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
              {/* <!-- Single News --> */}
              <div class="single-news">
                <div class="image">
                  <a href="https://sl-student-portal.web.app" target="_blank" rel="noreferrer"><img class="thumb" src="assets/images/sw/portal-3.png" alt="Blog" /></a>
                  {/* <div class="meta-details">
                    <img class="thumb" src="assets/images/blog/b6.jpg" alt="Author" />
                    <span>BY TIM NORTON</span>
                  </div> */}
                </div>
                <div class="content-body">
                  <h4 class="title">
                    <a href="https://sl-student-portal.web.app" target="_blank" rel="noreferrer"> 
                    Student Scheduling Portal</a>
                  </h4>
                  <p>
                    This portal is used for user signup and login. After successful vefication of the user account
                    and scheduling, a user can then be granted to access our laboratories.
                  </p>
                </div>
              </div>
              {/* <!-- End Single News --> */}
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              {/* <!-- Single News --> */}
              <div class="single-news">
                <div class="image">
                  <a href="https://sl-student-portal.web.app" target="_blank" rel="noreferrer"><img class="thumb" src="assets/images/about/elvis.jpg" alt="Blog" /></a>
                  {/* <div class="meta-details">
                    <img class="thumb" src="assets/images/blog/b6.jpg" alt="Author" />
                    <span>BY TIM NORTON</span>
                  </div> */}
                </div>
                <div class="content-body">
                  <h4 class="title">
                    <a href="https://sl-student-portal.web.app" target="_blank" rel="noreferrer">
                      The hardware platform
                    </a>
                  </h4>
                  <p>
                    We use physical hardware behind our Software. This consists of both third-party and in-house devices
                    depending on our partners' requirements.
                  </p>
                </div>
              </div>
              {/* <!-- End Single News --> */}
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              {/* <!-- Single News --> */}
              <div class="single-news">
                <div class="image">
                  <a href="https://sl-student-portal.web.app" target="_blank" rel="noreferrer"><img class="thumb" src="assets/images/sw/client-app.png" alt="Blog" /></a>
                  {/* <div class="meta-details">
                    <img class="thumb" src="assets/images/blog/b6.jpg" alt="Author" />
                    <span>BY TIM NORTON</span>
                  </div> */}
                </div>
                <div class="content-body">
                  <h4 class="title">
                    <a href="https://sl-student-portal.web.app" target="_blank" rel="noreferrer">
                      Client Software
                    </a>
                  </h4>
                  <p>
                    We provide a windows software package to allow access to our cloud hosted devices.
                    This is meant for single-time installation.
                  </p>
                </div>
              </div>
              {/* <!-- End Single News --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesArticles

