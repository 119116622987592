import { useField } from '../hooks'
import emailService from '../services/email'
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ContactForm() {
  const [showSpinner, setShowSpinner] = useState(false)
  const navigate = useNavigate()
  const message = useField('text')
  const name = useField('text')
  const phone = useField('text')
  const subject = useField('text')
  const email = useField('text')

  const handleClear = () => {
    email.reset();
    message.reset();
    name.reset();
    phone.reset()
    subject.reset();
  }

  const handleSubmit = (e) => {
    setShowSpinner(true);
    e.preventDefault()
    const data = {
      email: email.value,
      message: message.value,
      name: name.value,
      phone: phone.value,
      subject: subject.value
    }

    setTimeout(() => {
      emailService.send(data)
        .then(responseMsg => {
          handleClear()
          setShowSpinner(false)
          navigate("/operation/success")
        }).catch(error => {
          setShowSpinner(false)
          // console.log(error)
          handleClear();
          navigate("/operation/fail")
        })
    })

  }

  return (
    <>
      <form className="contact-form" onSubmit={handleSubmit} method='post'>
        <div className="row">
          <div className="col-md-6">
            <input name="name" {...Object.assign({}, name, { reset: undefined })} id="name" placeholder="Name" required />
          </div>
          <div className="col-md-6">
            <input type="email" name="email" {...Object.assign({}, email, { reset: undefined })} id="email" placeholder="Email" required />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <input name="phone" id="phone" {...Object.assign({}, phone, { reset: undefined })} placeholder="Phone" required />
          </div>
          <div className="col-md-6">
            <input name="subject" {...Object.assign({}, subject, { reset: undefined })} id="email" placeholder="Subject" required />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <textarea {...Object.assign({}, message, { reset: undefined, type: undefined })} name="message" id="message" placeholder="Type Message" rows="5"></textarea>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="button text-center rounded-buttons">
              <button type="submit" disabled={showSpinner} className="btn primary-btn rounded-full">
                Send Message
                {
                  showSpinner && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }
              </button>


            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ContactForm