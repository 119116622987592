import ContactForm from "./ContactForm"

function Contact() {
  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="contact-item-wrapper">
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-phone"></i>
                      </div>
                      <div className="contact-content">
                        <h4>Contact</h4>
                        <p>+256705638261</p>
                        <p>ashirafumiiro@outlook.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-map-marker"></i>
                      </div>
                      <div className="contact-content">
                        <h4>Address</h4>
                        <p>Makerere University</p>
                        <p>P.O BOX 7062</p>
                        <p>Kampala, Uganda</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <i className="lni lni-alarm-clock"></i>
                      </div>
                      <div className="contact-content">
                        <h4>Schedule</h4>
                        <p>8 Hours / 6 Days Open</p>
                        <p>Office time: 9 AM - 5:00 PM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="contact-form-wrapper">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 mx-auto">
                    <div className="section-title text-center">
                      <span> Get in Touch </span>
                      <h2>
                        Ready to Get Started
                      </h2>
                      <p>
                        Fill out the Details below to share a message with us.
                      </p>
                    </div>
                  </div>
                </div>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map-section map-style-9">
        <div className="map-container">
          <object style={{ border: 0, height: '500px', width: '100%' }} aria-labelledby="flower_id"
            data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7510828142786!2d32.56639741475324!3d0.33263469975731513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbb6d88e54def%3A0xddc6fcfbe10b089d!2sMakerere%20University!5e0!3m2!1sen!2sug!4v1665962694250!5m2!1sen!2sug"></object>
        </div>
      </section>
    </>
  )
}

export default Contact
