// import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'

function Navigation() {

  return (
    <>
      <section className="navbar-area navbar-nine sticky">
        <Container>
          <Row>
            <Col lg={12}>
              <Navbar collapseOnSelect expand="lg" as='nav'>
                <Navbar.Brand className='navbar-brand'>
                  <Link to='/'>
                    <img src="assets/images/logo-white.svg" width="250" height="39" alt="Logo" />
                  </Link>

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto" as='ul'>
                    <Nav.Link as='li' bsPrefix='nav-item'> <Link to='/' >Home</Link></Nav.Link>
                    <Nav.Link as='li' bsPrefix='nav-item'><Link to='/services' >Services </Link></Nav.Link>
                    <Nav.Link as='li' bsPrefix='nav-item'><Link to='/contact' >Contact </Link></Nav.Link>
                    <li className='nav-item'><a href="/" rel="noreferrer" target="_blank">Students</a></li>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Navigation
