import About from './About'

function Home() {
	const styles = {
		backgroundColor: '#F5FEFD'
	}
	return (
		<>
			<section className="header-area header-eight">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 col-12">
							<div className="header-content">
								<h1>Smarter Laboratories Limited</h1>
								<p>
									We are a company dedicated to improving access to learning material by providing online laboratories. 
									We design various laboratories which can be done from anywhere around the world.
								</p>
								<div className="button">
									<a href='/' rel="noreferrer" className="btn primary-btn" target="_blank">Schedule Now</a>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-12">
							<div style={styles} className="header-image">
								<img src="assets/images/elvis1.png" alt="#" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<About />
		</>
	)
}

export default Home
